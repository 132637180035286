import service from '@/until/api';

//中心云账号管理页面---查询所有角色
export function _FindAll(data) {
  return service({
    url: '/user-nacos/role/findAll',
    method: 'GET',
    params: data
  });
}

//中心云角色管理页面---分页查询
export function _FindAllRolePage(data) {
  return service({
    url: '/user-nacos/role/findAllPage',
    method: 'GET',
    params: data
  });
}

//中心云角色管理页面---添加角色
export function _AddRoleCenter(data) {
  return service({
    url: '/user-nacos/role/addRole',
    method: 'GET',
    params: data
  });
}

//中心云角色管理页面---修改角色名称

export function _UpdateRoleCenter(data) {
  return service({
    url: '/user-nacos/role/updateRole',
    method: 'GET',
    params: data
  });
}

//中心云角色管理页面---删除角色
export function _DeleteRoleCenter(data) {
  return service({
    url: '/user-nacos/role/deleteRole',
    method: 'GET',
    params: data
  });
}

//中心云角色管理页面--校验角色名是否唯一
export function _CheckRoleName(data) {
  return service({
    url: '/user-nacos/role/checkRoleName',
    method: 'GET',
    params: data
  });
}
//中心云角色管理页面--修改角色名时校验角色名是否唯一
export function _CheckUpdateRoleName(data) {
  return service({
    url: '/user-nacos/role/checkUpdateRoleName',
    method: 'GET',
    params: data
  });
}

//编辑角色的权限
export function _UpdateRoleMenu(data) {
  return service({
    url: '/system-nacos/menu/updateRoleMenu',
    method: 'POST',
    data
  });
}

/**
 * @msg: 2022-6-15 新增角色
 * @param {*} data
 */
export function _AddRole(data) {
  return service({
    url: '/protocol-nacos/center/role/addRole',
    method: 'POST',
    data
  });
}

/**
 * @msg: 2022-6-15 更新角色
 * @param {*} data
 */

export function _UpdateRole(data) {
  return service({
    url: '/protocol-nacos/center/role/updateRole',
    method: 'POST',
    data
  });
}

/**
 * @msg: 2022-6-15 查询所有园区列表
 */

export function _GetAllPark() {
  return service({
    url: '/user-nacos/center/park/list',
    method: 'get'
  });
}
