import service from '@/until/api'
// 查询所有菜单
export function _FindAllPage(params) {
  return service({
    url: '/protocol-nacos/center/park/authorizedParkData',
    method: 'GET',
    params
  })
}

/**
 * @msg: 更新配置
 * @param {*} data
 */
export function _UpdateConfig(data) {
  return service({
    url: '/protocol-nacos/center/park/updateAuthorizedParkData',
    method: 'post',
    data
  })
}

/**
 * @msg: 模板下载
 */
 export function _FileDownload() {
  return service({
    url: '/file-nacos/excelDownload/exportTemplate/车辆状态报文模版.pdf',
    responseType: 'blob',
    method: 'get'
  })
}