<template>
  <div>
    <div style="text-align: right">
      <el-button type="text" @click="templateGet">下载配置指导说明</el-button>
    </div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane
        v-for="(item, index) in configTpye"
        :key="index"
        :label="item.type"
        :name="index.toString()"
        ><div slot="label" class="title">
          {{ item.type }}
          <span class="num">
            <i class="el-icon-circle-check"></i>{{ item.checked.length }} 项
          </span>
        </div>
        <el-tree
          ref="tree"
          default-expand-all
          node-key="id"
          :data="treeData"
          :props="defaultProps"
          :default-checked-keys="item.checked"
          show-checkbox
          @check="upDateNode(item, index)"
        ></el-tree>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
           
<script>
import { _FileDownload } from '@/api/sassManagement/dataAuthManage'
import { saveAs } from 'file-saver'
export default {
  props: {
    nowRow: {
      tpye: Object
    }
  },
  data() {
    return {
      activeName: '0',
      configTpye: [],
      treeData: [],
      defaultProps: {
        label: 'menuName'
      }
    }
  },
  created() {
    this.formatType()
  },
  methods: {
    // 格式化配置项
    formatType() {
      this.treeData = this.nowRow.parkDataMenuResponse
      let arr = []
      this.nowRow.carTypes.forEach(ele => {
        let obj = {}
        obj.type = ele
        if (this.nowRow.authDataIdMap && this.nowRow.authDataIdMap[ele]) {
          obj.checked = this.nowRow.authDataIdMap[ele]
        } else {
          obj.checked = []
        }
        arr.push(obj)
      })
      this.configTpye = arr
      // console.log(this.configTpye.checked)
    },
    // 获取更新数据
    getUpdateCofig() {
      let obj = {}
      this.configTpye.forEach((ele, index) => {
        obj[ele.type] = this.$refs.tree[index].getCheckedKeys()
      })
      return obj
    },
    // 更新选中节点个数
    upDateNode(item, index) {
      item.checked = this.$refs.tree[index].getCheckedKeys()
    },
    // 获取文件模板
    async templateGet() {
      let res = await _FileDownload()
      let blob = new Blob([res])
      saveAs(blob, '车辆状态报文模版.pdf')
    }
  }
}
</script>
<style lang='less' scoped>
.el-tabs__item {
  padding-bottom: 20px;
}
.title {
  position: relative;
  padding: 0 8px;
  .num {
    position: absolute;
    font-size: 12px;
    bottom: -13px;
    right: -14px;
  }
}
</style>