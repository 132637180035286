<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-select
          placeholder="请选择园区"
          class="centerSelInput"
          v-model="searchObj.parkName"
        >
          <el-option
            v-for="item in parkList"
            :key="item.parkId"
            :label="item.parkName"
            :value="item.parkName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          placeholder="请选择配置状态"
          class="centerSelInput"
          v-model="searchObj.interfaceConfigFlag"
        >
          <el-option value="0" label="未配置"></el-option>
          <el-option value="1" label="已配置"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <button class="centerCzbtn" type="button" @click="getTable(searchPage)">
          搜索
        </button>
      </el-form-item>
      <el-form-item>
        <button class="centerBtn resetbtn" @click="reset" type="button">
          重置
        </button>
      </el-form-item>
    </el-form>
    <Table
      :data1="tabledata"
      :columns1="columns"
      :total="total"
      @getAjax="getTable"
      :loading="loading"
      :page="searchObj.page"
      :size="searchObj.size"
    />
    <!-- 配置 -->
    <el-drawer
      title="配置权限"
      :visible.sync="configDrawer"
      size="40%"
      destroy-on-close
      custom-class="configDrawer"
    >
      <authPark :nowRow="nowRow" style="flex: 1" ref="authPark"></authPark>
      <div style="text-align: right; margin: 10px 0">
        <el-button
          type="primary"
          size="medium"
          @click="updateConfig"
          :loading="updateLoading"
          >保存</el-button
        >
        <el-button size="medium" @click="configDrawer = false">取消</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Table from '../../components/Table.vue'
import {
  _FindAllPage,
  _UpdateConfig
} from '@/api/sassManagement/dataAuthManage'
import authPark from './components/auth-park.vue'
import { _GetAllPark } from '@/api/systemManagement/roleManage'
export default {
  components: {
    Table,
    authPark
  },
  data() {
    return {
      loading: false,
      total: 0,
      tabledata: [],
      searchObj: {
        parkName: '',
        page: 1,
        size: 10,
        interfaceConfigFlag: null
      },
      parkList: [],
      searchPage: {
        limit: 10,
        page: 1
      },
      vehicleopTypeList: [],
      columns: [
        {
          label: '园区',
          prop: 'parkName'
        },
        {
          label: '车辆类型',
          prop: 'carTypes',
          render: (h, { row }) => {
            let label = '--'
            if (!row.carTypes?.length || !row.carTypes) {
              return h('span', label)
            } else {
              label = row.carTypes.join('、')
              return h('span', label)
            }
          }
        },
        {
          label: '接口配置状态',
          prop: 'interfaceConfigFlag',
          render: (h, { row }) => {
            return h('span', [
              h('div', {
                class: row.interfaceConfigFlag ? 'hasSet' : 'notSet'
              }),
              h('span', {}, row.interfaceConfigFlag ? '已配置' : '未配置')
            ])
          }
        },
        {
          label: '配置时间',
          prop: 'interfaceConfigAddTime'
        },
        {
          label: '添加人',
          prop: 'interfaceConfigAdder'
        },
        {
          label: '操作项',
          width: 120,
          render: (h, { row }) => {
            if (!row.carTypes?.length || !row.carTypes) {
              return h('span', '--')
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'operationA colorB',
                    on: {
                      click: () => {
                        this.configDrawer = true
                        this.nowRow = row
                      }
                    }
                  },
                  row.interfaceConfigFlag ? '修改配置' : '配置'
                )
              ])
            }
          }
        }
      ],
      configDrawer: false,
      nowRow: null,
      updateLoading: false
    }
  },
  methods: {
    // 获取全部园区
    async getAllPark() {
      try {
        let { data } = await _GetAllPark()
        this.parkList = data
      } catch (error) {
        this.$message.error('获取园区失败！')
      }
    },
    async getTable(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.searchObj.page = pagination.page
          this.searchObj.size = pagination.limit
        }
      }
      this.loading = true
      let { success, data } = await _FindAllPage(this.searchObj)
      if (success) {
        this.tabledata = data.list
        this.total = data.total
        this.loading = false
      }
    },
    reset() {
      this.searchObj = {
        typeName: '',
        page: 1,
        size: 10
      }
      this.getTable()
    },
    // 保存配置
    async updateConfig() {
      let obj = {
        parkName: this.nowRow.parkName,
        authDataIdMap: this.$refs.authPark.getUpdateCofig()
      }
      this.updateLoading = true
      try {
        let { success, data } = await _UpdateConfig(obj)
        if (success) {
          this.$message.success(data)
          this.getTable()
          this.configDrawer = false
        }
      } finally {
        this.updateLoading = false
      }
    }
  },
  created() {
    this.getAllPark()
    this.getTable()
  }
}
</script>
<style scope lang="less">
.hasSet {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: green;
  margin-right: 4px;
}
.notSet {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: red;
  margin-right: 4px;
}
</style>
<style lang="less">
.configDrawer {
  .el-drawer__header {
    margin-bottom: 0;
  }
  .el-drawer__body {
    display: flex;
    flex-direction: column;
  }
}
</style>
